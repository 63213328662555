<template>
    <div class="question-item">
        <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
        <div>
            <Select v-model="val" clearable style="width:300px">
                <Option v-for="(option, index) in options" :value="option" :key="index">{{option}}</Option>
            </Select>
        </div>
    </div>
</template>
<script>
export default {
    name: "DropdownChoice",
    props: ["title",  "required", "options", "pageInfo"],
    data() {
        return {
            val: ""
        }
    }
}
</script>